<template>
  <v-card class="pa-1">
    <v-container>

      <!-- <Select
        icon="mdi-calendar-clock"
        itemText="tipo"
        itemValue="token"
        v-model="value.practica"
        :items="lista_practicas"
        label="Elige una Práctica"
        rules="required"
        name="La practica"
      /> -->

      <p class="mb-1">Por favor, elige el tipo de solicitud que deseas realizar: </p>

      <Select
        icon="mdi-calendar-clock"
        itemText="nombre"
        itemValue="id"
        v-model="value.tipo_solicitud"
        :items="tipos_solicitud"
        label="Tipo de solicitud"
        rules="required"
        name="El tipo de solicitud"
      />

      <v-sheet v-if="value.tipo_solicitud == 1">
        <v-container>
          <v-row class="mb-3">Ingresa los datos de tu nuevo supervisor</v-row>

          <TextField
            name="El nombre del supervisor"
            rules="required"
            label="Nombre"
            v-model="value.supervisor.nombre"
            icon="mdi-form-textbox"
          ></TextField>

          <TextField
            name="El apellido del supervisor"
            rules="required"
            label="Apellido"
            v-model="value.supervisor.apellido"
            icon="mdi-form-textbox"
          ></TextField>

          <TextField
            name="El e-mail del supervisor"
            rules="required|email"
            label="E-mail"
            v-model="value.supervisor.email"
            icon="mdi-form-textbox"
          ></TextField>

          <TextField
            name="El telefono del supervisor"
            rules="required|max:18|telefono"
            label="Telefono"
            counter="18"
            v-model="value.supervisor.telefono"
            icon="mdi-form-textbox"
          ></TextField>
          
        </v-container>
      </v-sheet>
      <v-sheet v-if="value.tipo_solicitud == 2">
        <v-container>
          <v-row class="mb-3">Ingresa la nueva fecha de inicio</v-row>
          <DatePicker
        v-model="value.fecha_inicio"
        label="Fecha de inicio"
        name="La fecha de inicio"
        rules="required"
      />

        </v-container>
      </v-sheet>
      <v-sheet v-if="value.tipo_solicitud == 3">
        <v-container>
          <v-row class="mb-3">Ingresa el nuevo modo de práctica</v-row>
          <ValidationProvider
            v-slot="{ errors }"
            name="El modo de la práctica"
            rules="required"
          >
            <v-select
              :items="modos_local"
              rounded
              :error-messages="errors"
              v-model="value.modo"
              prepend-inner-icon="mdi-calendar-clock"
              item-value="id"
              item-text="nombre"
              filled
              label="Modo de Práctica"
            ></v-select>
          </ValidationProvider>
        </v-container>
      </v-sheet>

      <v-sheet v-if="value.tipo_solicitud == 4"
        >Se reenviará el mail de confirmación de tu práctica a tu
        supervisor
      </v-sheet>

      <v-sheet v-if="value.tipo_solicitud == 5"
        >Se reenviará el mail de evaluación de tu práctica a tu
        supervisor
      </v-sheet>

      <v-sheet>
        <v-container>
          <v-row class="mb-3">Ingresa el motivo del cambio</v-row>
          <ValidationProvider
            v-slot="{ errors }"
            name="El motivo del cambio"
            rules="required"
          >
            <v-textarea
              rounded
              :error-messages="errors"
              v-model="value.mensaje"
              no-resize
              filled
              label="Motivo"
            ></v-textarea>
          </ValidationProvider>
        </v-container>
      </v-sheet>
    </v-container>
  </v-card>
</template>

<script>

import DatePicker from '@/components/forms/DatePicker.vue'
import {ValidationProvider} from "vee-validate";
import Select from "@/components/forms/Select.vue";
import TextField from "@/components/forms/TextField.vue";
import PerfilScreen from '@/views/alumno/Perfil/PerfilScreen.vue';


export default {
  components: {
    ValidationProvider,
    Select,
    TextField,
    DatePicker
  },
  data: () => ({
    modos_local: [],
    modos_cargados: false,
  }),
  props:['value', 'practica_token'],
  computed: {
    modos() {
      return this.$store.state.tables.modos_practica.items;
    },
    tipos_solicitud() {
      return this.$store.state.tables.tipos_solicitud.items;
    },
    
    // lista_practicas() {
    //   return this.$store.state.tables.lista_practicas.items;
    // },
    practicas() {
      return this.$store.state.tables.practicas.items;
    },
  },

  watch: {
    modos: function() {
      this.modos_cargados = true;
      this.modos_local = this.modos;
    },
    
    // - Pasos (practicas.paso) -
    // 0: Sin prácticas inscritas
    // 1: Primera práctica inscrita (Industrial: Ing. Civil Info.
    // 2: Sin segunda práctica inscrita (solo Ing. Civil Info.)
    // 3: Segunda práctica inscrita  || Profesional: Civil inf y Ing. Ejecución Inf

    modos_cargados() {
      if(this.practicas.paso == 1){ // Práctica Industrial
        this.modos_local = this.modos.filter(function(modo) {
          //Quito los modos de 3 meses para la industrial
          if (modo.estado == 1 ){
            if (![540,480,486].includes(modo.cantidad_horas)) {
              return modo;
            }
          }
        });
      }
      if(this.practicas.paso == 3){ // Práctica profesional Civil y ejecución
        if (this.practicas.profesional.carrera == "Ingenieria en Ejecucion Informatica") {
          this.modos_local = this.modos.filter(function(modo) {
            //Quito los modos de 3 meses para la profesional de los de Ejecucion Informatica
            if (modo.estado == 1 ){
              if (![540,480,486].includes(modo.cantidad_horas)) {
                return modo;
              }
            }
          });
        }
        else if (this.practicas.profesional.carrera == "Ingeniería Civil Informática") {
          this.modos_local = this.modos_local.filter((modo) => {
            if (modo.estado == 1 ){


              if (![180,160,162].includes(modo.cantidad_horas)) { //Descarto los modos de 1 mes
                console.log(modo.cantidad_horas)
                if ( [360,320,324].includes(this.practicas.industrial.cantidad_horas_modo )) {
                  if (![540,480,486].includes(modo.cantidad_horas )) { //Si PI es 2 meses, quito las opciones de 3 meses
                    return modo;
                  }
                }
                else if ([180,160,162].includes(this.practicas.industrial.cantidad_horas_modo)) {
                  if ([540,480,486].includes(modo.cantidad_horas)) { //Si PI es 1 meses, dejo solo las opciones de 3 meses
                    return modo;
                  }
                }
              }
            }
          });
        }
      }
    },

  },
  created() {
    this.$store.dispatch("tables/get", { endpoint: 'practica/modos', table: "modos_practica" });
    // this.$store.dispatch("tables/get", { endpoint: 'solicitudes/practicas', table: "lista_practicas" });
    this.$store.dispatch("tables/get", { endpoint: 'solicitudes/tipos', table: "tipos_solicitud" });

    this.value.practica = this.practica_token
  },
};
</script>

<style></style>
