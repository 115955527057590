<!-- Tipo 0 -> Industrial -->
<!-- Tipo 1 -> Profesional -->
<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <v-row no-gutters>
        <v-col :cols="12" md="6" class="pl-5 pr-5">
          <v-sheet>
            <v-sheet>
              <Title text="Datos de la Empresa" />
              <v-row>
                <v-col>
                  <AutoComplete
                    kind="empresa"
                    :filter="customFilter"
                    :onChange="cargarSucursal"
                    :items="empresas"
                    v-model="empresa"
                    name="La empresa"
                    itemValue="id"
                    itemText="nombre"
                    label="Empresa"
                  />
                </v-col>
                <v-col class="col-auto">
                  <ButtonIcon
                  class="mt-2"
                    icon="add"
                    :onClick="() => (modalNuevaEmpresa = true)"
                  />
                </v-col>
              </v-row>

              <TextField
                label="RUT de la Empresa"
                :value="findrut"
                disabled
                icon="mdi-numeric"
              ></TextField>

              <v-row v-if="mostrarSucursales">
                <v-col>
                  <!-- si se selecciona una empresa con sucursal registrada,
                  y luego cambio a otra sin sucursales, aparecen las de la primera -->
                  <!-- en volá se arregla con un v-if (if size > 0 || != null) -->
                  <AutoComplete
                    kind="sucursal"
                    :items="sucursales"
                    v-model="sucursal"
                    name="La sucursal"
                    itemValue="id"
                    itemText="direccion"
                    label="Sucursal"
                  />
                </v-col>
                <v-col class="col-auto">
                  <ButtonIcon
                  class="mt-2"
                    icon="add"
                    :onClick="() => (modalNuevaSucursal = true)"
                  />
                </v-col>
              </v-row>
              <p align="justify">A continuación, enumera formalmente las tareas realizadas, estas mismas serán enviadas a Dirección de Estudios para su aprobación.</p>
              <TextArea
                v-if="tipoPractica == 2"
                rules="required|max:1500"
                v-model="practicaToCreate.tareas_realizadas"
                label="Resumen de las tareas realizadas"
                name="El resumen de las tareas realizadas"
              />

            </v-sheet>
            <v-sheet v-if="tipoPractica == 0">
              <Title text="Datos del Supervisor" />

              <TextField
                label="Nombre"
                v-model="practicaToCreate.supervisor.nombre"
                name="El nombre del supervisor"
                rules="required|max:25"
                counter="25"
              />
              <TextField
                label="Apellido"
                v-model="practicaToCreate.supervisor.apellido"
                name="El apellido del supervisor"
                rules="required|max:25"
                counter="25"
              />
              <TextField
                label="E-mail"
                v-model="practicaToCreate.supervisor.email"
                name="El email del supervisor"
                rules="required|email|max:50"
                counter="50"
              />
              <TextField
                label="Telefono"
                v-model="practicaToCreate.supervisor.telefono"
                name="El telefono del supervisor"
                counter="18"
                rules="required|max:18|telefono"
              />
            </v-sheet>

            <v-sheet v-if="tipoPractica == 2">
                <Title  text="Datos del Alumno" />
                
                <p>{{textos_archivos.p}}</p>

                <FileInput
                  v-model="practicaToCreate.curriculum"
                  :label="textos_archivos.curriculum"
                  :name="textos_archivos.curriculum_name"
                  rules="required"
                />
                <FileInput
                  v-model="practicaToCreate.boletas"
                  :label="textos_archivos.boletas"
                  :name="textos_archivos.boletas_name"
                  rules="required"
                />
                <FileInput
                  v-model="practicaToCreate.carta"
                  :label="textos_archivos.carta"
                  :name="textos_archivos.carta_name"
                  rules="required"
                />

            </v-sheet>
          </v-sheet>
        </v-col>
        <v-col :cols="12" md="6">
          <Title class="ml-3" text="Datos de la práctica" />

          <Select
            label="Modo de Práctica"
            :items="modos_local"
            v-model="practicaToCreate.modo"
            itemValue="id"
            itemText="nombre"
            icon="mdi-calendar-clock"
            name="El modo de practica"
            rules="required"
          />
          <Select
            label="Carrera"
            :items="carreras"
            :disabled="true"
            v-model="practicaToCreate.carrera"
            itemValue="id"
            itemText="nombre"
            icon="mdi-school"
            name="La carrera"
            rules="required"
          />
          <Select
            label="Campus"
            :items="campus"
            :disabled="true"
            v-model="practicaToCreate.campus"
            itemValue="id"
            itemText="nombre"
            icon="mdi-city"
            name="El campus"
            rules="required"
          />


          <p v-if="tipoPractica == 2">
            A continuación indícanos las fechas entre las cuales estuviste trabajando.
          </p>
          <div>
            <!-- <DatePicker
              v-model="practicaToCreate.fecha_inicio"
              v-if="this.practicas.industrial"
              :min="this.practica.industrial.fecha_termino"
              label="Fecha de inicio"
              name="La fecha de inicio"
              rules="required"
            /> -->
            <DatePicker
              v-model="practicaToCreate.fecha_inicio"
              label="Fecha de inicio"
              name="La fecha de inicio"
              rules="required"
            />

            <DatePicker
              v-if="tipoPractica == 2"
              v-model="practicaToCreate.fecha_termino"
              label="Fecha de termino"
              name="La fecha de termino"
              rules="required"
            />
          </div>
          <div>
            <Title class="ml-3" text="Consideracíon especial (opcional)" />
            <p class="ml-3">Si al momento de realizar la práctica tienes algun comentario respecto a alguna condición a considerar, por favor indicanoslo aqui. (Ej: Situación financiera, estás diagnosticado con TEA, entre otros...) </p>
            <p class="ml-3"> <strong> Esta información será totalmente confidencial y solamente el profesor y ayudante encargados tendrán acceso.</strong></p>
          <TextArea
                rules="max:300"
                v-model="practicaToCreate.consideracion"
                label="Considreación especial"
                name="Las consideración"
              />
          </div>
        

        </v-col>
      </v-row>

      <BottomArrowsStep
        nextText="Ingresar Práctica"
        backText="Atrás"
        :backAction="backAction"
        :nextAction="nextAction"
        :nextDisabled="invalid"
      />
    </ValidationObserver>

    <ValidationObserver disabled v-slot="{ invalid }">
      <ActionModal
        :active="modalNuevaEmpresa"
        title="Nueva empresa"
        :successAction="createEmpresa"
        :cancelAction="cerrarModalNuevaEmpresa"
        :nextDisabled="invalid"
      >
        <v-container>
          <v-row>
            <v-col>
              <p>
                Si no encuentras la empresa, puedes ingresarla manualmente (La
                información de tu usuario quedará vinculado a la empresa creada,
                por favor, ingresa datos fidedignos o puedes arriesgarte a una
                sanción)
              </p>
              <TextField
                name="El RUT"
                :isRutEmpresa="true"
                label="Rut de la Empresa: (Ej: XX.XXX.XXX-X)"
                v-model="newEmpresa.rut"
                counter="13"
                rules="required|min:8|max:13|rutempresa"
              />
              <TextField
                name="El nombre"
                label="Nombre de la Empresa:"
                counter="100"
                v-model="newEmpresa.nombre"
                rules="required|min:3|max:100"
              />
            </v-col>
          </v-row>
        </v-container>
      </ActionModal>
    </ValidationObserver>
    <ValidationObserver disabled v-slot="{ invalid }">
      <ActionModal
        :active="modalNuevaSucursal"
        title="Nueva Sucursal"
        :successAction="createSucursal"
        :cancelAction="cerrarModalNuevaSucursal"
        :nextDisabled="invalid"
      >
        <v-container>
          <v-row>
            <v-col>
              <p>
                Si no encuentras la sucursal, puedes ingresarla manualmente (La
                información de tu usuario quedará vinculada a la sucursal
                creada, por favor, ingresa datos fidedignos o puedes arriesgarte
                a una sanción)
              </p>

              <ValidationProvider
                v-slot="{ errors }"
                name="El pais"
                rules="required"
              >
                <v-autocomplete
                  :items="paises"
                  prepend-inner-icon="domain"
                  v-model="newSucursal.pais_id"
                  item-value="id"
                  rounded
                  :error-messages="errors"
                  item-text="name"
                  filled
                  label="País"
                  @change="cargarRegiones()"
                ></v-autocomplete>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="La region"
                rules="required"
              >
                <v-autocomplete
                  :items="regiones"
                  :error-messages="errors"
                  prepend-inner-icon="domain"
                  v-model="newSucursal.region_id"
                  item-value="id"
                  rounded
                  item-text="name"
                  filled
                  label="Region"
                ></v-autocomplete>
              </ValidationProvider>
              <TextField
                label="Dirección:"
                v-model="newSucursal.direccion"
                rules="required|min:5|max:100"
                name="La dirección"
                counter="100"
              />

              <p>¡Importante!: Si la nueva surcursal creada no se selecciona automaticamente, porfavor recarga la pagina y aparecerá en el listado.</p>

            </v-col>
          </v-row>
        </v-container>
      </ActionModal>
    </ValidationObserver>
  </div>
</template>

<script>
import BottomArrowsStep from '../../../../components/forms/BottomArrowsStep.vue'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DatePicker from "@/components/forms/DatePicker";
import AutoComplete from "@/components/forms/AutoComplete";
import Select from "@/components/forms/Select";
import FileInput from "@/components/forms/FileInput";
import TextField from "@/components/forms/TextField";
import TextArea from "@/components/forms/TextArea";
import ButtonIcon from "@/components/forms/ButtonIcon";
import ActionModal from "@/components/ActionModal";
import Title from "@/components/forms/Title";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    ActionModal,
    ButtonIcon,
    AutoComplete,
    DatePicker,
    Select,
    FileInput,
    TextField,
    TextArea,
    Title, BottomArrowsStep
  },
  data: () => ({
    modos_cargados: false,
    practica_cargada: false,
    modos_local: [],
    menu: false,
    modalNuevaEmpresa: false,
    modalNuevaSucursal: false,
    rules: [
      (v) => !!v || "File is required",
      (v) => (v && v.length > 0) || "File is required",
    ],
    mostrarSucursales: false,
    empresa: null,
    sucursal: null,
    newEmpresa: {
      nombre: "",
      rut: "",
    },
    newSucursal: {
      direccion: "",
      pais_id: 44,
      region_id: 2824,
    },
    defaultEmpresa: {
      nombre: "",
      rut: "",
    },
    defaultSucursal: {
      direccion: "",
      pais_id: 44,
      region_id: 2824,
    },

    textos_archivos: {
      p:"A continuación sube tu curriculum donde esté especificado el trabajo realizado, las boletas o cotizaciones que generaste por este trabajo y una carta de un jefe o supervisor directo en la empresa en la que valide la calidad te tu trabajo realizado.",
      curriculum: "Curriculum actualizado",
      curriculum_name: "El curriculum",
      boletas: "Boletas emitidas o Certificado de imposiciones previsionales",
      boletas_name: "Las boletas",
      carta: "Carta de Jefe o supervisor directo en la empresa",
      carta_name: "La Carta",
    },
  }),

  props: ["practicaToCreate", "tipoPractica", "practicas","backAction","nextAction"],
  created() {
    this.$store.dispatch("tables/get", { endpoint: "paises", table: "paises" });
    this.$store.dispatch("tables/get", {
      endpoint: "regiones",
      table: "regiones",
      params: "?pais=" + this.newSucursal.pais_id,
    });

    this.$store.dispatch("tables/get", { endpoint: "campus",  table: "campus" });
    this.$store.dispatch("tables/get", { endpoint: "carreras", table: "carreras" });
    this.$store.dispatch("tables/get", { endpoint: "practica/tipos", table: "tipos_practica" });
    this.$store.dispatch("tables/get", { endpoint: 'practica/modos', table: "modos_practica" });
    this.$store.dispatch("tables/get", { endpoint: "empresas", table: "empresas" });
  },

  computed: {
    campus() {
      return this.$store.state.tables.campus.items;
    },
    paises() {
      return this.$store.state.tables.paises.items;
    },
    regiones() {
      return this.$store.state.tables.regiones.items;
    },
    carreras() {
      return this.$store.state.tables.carreras.items;
    },
    modos() {
      return this.$store.state.tables.modos_practica.items;
    },
    empresas() {
      return this.$store.state.tables.empresas.items;
    },
    sucursales() {
      return this.$store.state.tables.sucursales.items;
    },

    // Encuentra el RUT dado que se seleccionó la empresa
    findrut() {
      if (this.empresas != undefined) {
        var selected = this.empresas.find(
          (element) => element.id == this.empresa
        );
        if (selected != null) {
          return selected.rut;
        }
      }
      return null;
    },

    // Encuentra la empresa dado que se seleccionó el RUT
    /*
    findempresa() {
      if (this.empresas != undefined) {
        var selected = this.empresas.find(
          (element) => element.rut == this.findrut
        );
        if (selected != null) {
          return selected.id;
        }
      }
      return null;
    },
    */
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    modos: function() {
      this.modos_cargados = true;
      this.modos_local = this.modos;
    },
    practicas: function() {
      this.practica_cargada = true;
    },
    sucursal: function(newVal) {
      this.practicaToCreate.sucursal = newVal;
    },
    'practicaToCreate.categoria': function(newVal) {
      if (newVal == 5){
        this.textos_archivos =  {
          p:"A continuación sube tu curriculum que incluya las bitacoras,el plan operacional junto al modelo de negocio y el documento con links a página web y RRSS del emprendimiento.",
          curriculum: "Curriculum actualizado con bitacoras",
          curriculum_name: "El curriculum",
          boletas: "Plan Operacional y Modelo de Negocios",
          boletas_name: "El plan operacional",
          carta: "Página web y RRSS",
          carta_name: "La web y RRSS",
          }
      }else{
        this.textos_archivos =   {
          p:"A continuación sube tu curriculum donde esté especificado el trabajo realizado, las boletas o cotizaciones que generaste por este trabajo y una carta de un jefe o supervisor directo en la empresa en la que valide la calidad te tu trabajo realizado.",
          curriculum: "Curriculum actualizado",
          curriculum_name: "El curriculum",
          boletas: "Boletas emitidas o Certificado de imposiciones previsionales",
          boletas_name: "Las boletas",
          carta: "Carta de Jefe o supervisor directo en la empresa",
          carta_name: "La Carta",
        }
      }

    },
    
    
    // practica_cargada y modos_cargados ES LO MISMOOO

    // - Pasos (practicas.paso) -
    // 0: Sin prácticas inscritas
    // 1: Primera práctica inscrita (Industrial: Ing. Civil Info.
    // 2: Sin segunda práctica inscrita (solo Ing. Civil Info.)
    // 3: Segunda práctica inscrita  || Profesional: Civil inf y Ing. Ejecución Inf


    practica_cargada() {
// || carrera == 2 ??
      //Caso base de la carrera de Informática en Ejecución
      if (this.practicaToCreate.carrera == 3) {
        this.modos_local = this.modos.filter(function(modo) {
          //Se filtra a los modos locales la practica de 320 horas
          if (modo.estado == 1){
            if (modo.cantidad_horas == 320 && modo.cantidad_horas == 324) {
              return modo;
            }
         }
        });
      }

      //Caso carrera Civil Informática

      // Práctica Industrial
      if (this.modos_cargados && this.practica_cargada) {
        
        // Esto no debería mostrarse en paso == 0?
        //R: De hecho si xd porque el paso 1 es ya inscrita  lo dejo asi igual uwu
        if (this.practicas.paso == 0 || this.practicas.paso == 1) {
          this.modos_local = this.modos.filter(function(modo) {

            if (modo.estado == 1){ //Verifico que el modo este activo
              if ( ![540,480,486].includes(modo.cantidad_horas)) {//  descarta modos equivalentes a 3 meses
                return modo;
              }
           }

          });
        }
        // Práctica Profesional
        if (this.practicas.paso == 2) {
          this.modos_local = this.modos_local.filter((modo) => {
            if (modo.estado == 1){


              if (modo.cantidad_horas != 160 && modo.cantidad_horas != 180 && modo.cantidad_horas != 162) { //Descarto los modos de 1 mes
                if ( [360,320,324].includes(this.practicas.industrial.cantidad_horas_modo )) {
                  if (![540,480,486].includes(modo.cantidad_horas )) { //Si PI es 2 meses, quito las opciones de 3 meses
                    return modo;
                  }
                }
                else if ([180,160,162].includes(this.practicas.industrial.cantidad_horas_modo)) {
                  if ([540,480].includes(modo.cantidad_horas )) { //Si PI es 1 meses, dejo solo las opciones de 3 meses
                    return modo;
                  }
                }
              }
            }
          });
        }
      }
    },

    modos_cargados() {
      // || carrera == 2 ??
      //Caso base de la carrera de Informática en Ejecución
      if (this.practicaToCreate.carrera == 3) {
        this.modos_local = this.modos.filter(function(modo) {
          console.log(modo)
          //Se filtra a los modos locales la practica de 320 horas
          if (modo.estado == 1){
            if (modo.cantidad_horas == 320 && modo.cantidad_horas == 324) {
              return modo;
            }
         }
        });
      }

      //Caso carrera Civil Informática

      // Práctica Industrial
      if (this.modos_cargados && this.practica_cargada) {
        
        // Esto no debería mostrarse en paso == 0?
        //R: De hecho si xd porque el paso 1 es ya inscrita  lo dejo asi igual uwu
        if (this.practicas.paso == 0 || this.practicas.paso == 1) {
          this.modos_local = this.modos.filter(function(modo) {

            if (modo.estado == 1){ //Verifico que el modo este activo
              if ( ![540,480,486].includes(modo.cantidad_horas)) {//  descarta modos equivalentes a 3 meses
                return modo;
              }
           }

          });
        }
        // Práctica Profesional
        if (this.practicas.paso == 2) {
          this.modos_local = this.modos_local.filter((modo) => {
            if (modo.estado == 1){


              if (modo.cantidad_horas != 160 && modo.cantidad_horas != 180 && modo.cantidad_horas != 162) { //Descarto los modos de 1 mes
                if ( [360,320,324].includes(this.practicas.industrial.cantidad_horas_modo )) {
                  if (![540,480,486].includes(modo.cantidad_horas )) { //Si PI es 2 meses, quito las opciones de 3 meses
                    return modo;
                  }
                }
                else if ([180,160,162].includes(this.practicas.industrial.cantidad_horas_modo)) {
                  if ([540,480,486].includes(modo.cantidad_horas )) { //Si PI es 1 meses, dejo solo las opciones de 3 meses
                    return modo;
                  }
                }
              }
            }
          });
        }
      }
    }
  },

  methods: {
    cargarRegiones() {
      this.$store.dispatch("tables/get", {
        endpoint: 'regiones',
        table: "regiones",
        params: "?pais=" + this.newSucursal.pais_id,
      });
      this.newSucursal.region_id = null;
    },

    cerrarModalNuevaEmpresa() {
      this.modalNuevaEmpresa = false;
      this.newEmpresa = Object.assign({}, this.defaultEmpresa);
    },

    cerrarModalNuevaSucursal() {
      this.modalNuevaSucursal = false;
      this.newSucursal = Object.assign({}, this.defaultSucursal);
    },

    createEmpresa() {
      this.$store.dispatch("tables/new", {
        endpoint: `empresas`,
        table: "empresas",
        item: this.newEmpresa,
        error_message: "Ha ocurrido un error al añadir la Empresa.",
        success_message: "Empresa creada correctamente.",
      }).then(data => {
        this.empresa = data.id;
        this.cargarSucursal();
      });
      this.modalNuevaEmpresa = false;
    },

    createSucursal() {
      this.$store.dispatch("tables/new", {
        endpoint: `empresas/${this.empresa}/sucursales`,
        table: "sucursales",
        item: this.newSucursal,
        error_message: "Ha ocurrido un error al añadir la Empresa.",
        success_message: "Empresa creada correctamente.",
      }).then(data => {
        this.sucursal = data.id;
        this.cargarSucursal()
      });
      this.modalNuevaSucursal = false;

    },

    //Hace la peticion para cargar las sucursales una vez seleccionada una empresa
    cargarSucursal() {
      this.$store.state.tables.sucursales.items = [];
      this.$store.dispatch("tables/get", {
        endpoint: `empresas/${this.empresa}/sucursales`,
        table: "sucursales",
      });
      this.mostrarSucursales = true
      /*.then((response) => {
        if (response.status == 200){
          this.mostrarSucursales = true
        } else {
          this.mostrarSucursales = false
        }
      })*/
    },

    //Filtro personalizado que busca empresa por RUT o Nombre de empresa
    customFilter(item, queryText, itemText) {
      const textOne = item.nombre.toLowerCase();
      const textTwo = item.rut.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
  },
};
</script>

<style></style>
