<!-- Componente del formulario de la evaluación de competencias -->
<template>
     <v-item-group v-model="notas[competencia.id]">
        <v-row>
        <v-row justify="space-around">
            <h2>{{ competencia.nombre }}</h2>
        </v-row>

        <v-col
            cols="12"
            v-for="descripcion in competencia.descripciones"
            :key="descripcion.numero"
        >
            <v-item v-slot:default="{ active, toggle }">
            <v-card

                :color="active ? 'primary' : ''"
                class="d-flex align-center pa-3 number"
                :style="{fontSize: '14px'}"
                :class="
                active
                    ? 'white--text font-weight-bold with_border'
                    : 'with_border'
                "
                @click="toggle"
            >
                <v-avatar
                class="mr-5"
                :color="active ? 'white' : 'primary'"
                >
                <h2
                    :class="
                    active ? 'primary--text' : 'white--text'
                    "
                >
                    {{ descripcion.numero }}
                </h2>
                </v-avatar>
                {{ descripcion.descripcion }}
                <v-scroll-y-transition>
                <div
                    v-if="active"
                    class="display-3 flex-grow-1 text-center"
                ></div>
                </v-scroll-y-transition>
            </v-card>
            </v-item>
        </v-col>
        </v-row>
    </v-item-group>
</template>

<script>
export default {
    props: {
        competencia: {
            type: Object,
            required: true,
        },
        notas: {
            type: Object,
            required: true,
        },
    },
};
</script>