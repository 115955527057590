<!-- Componente de la evaluación de la práctica -->
<!-- Puede acceder cualquiern usuario sin iniciar sesion -->
<!-- El proceso está separado en steps que son los que se indican un poco más abajo -->
<template>
  <v-container v-if="evaluation">
    <v-dialog
        v-model="dialog_reevalucion"
        width="600"
        persistent
        v-if="evaluation.ev_previa"

      >
        <v-card>
          <v-card-title >
            Advertencia de reevaluación
          </v-card-title>

          <v-card-text>
            
            Estimad@ Supervisor/a, esta es una reevaluación de una práctica que ya se encuentra evaluada. 
            Todos los campos y notas puestos en la evaluación anterior estarán ya ingresados, los cuales puede modificar
            o mantener a su criterio. Los nuevos datos que sean ingresados reemplazarán los ya existentes.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="dialog_reevalucion = false"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    
    <v-row justify="space-around">
      <logo></logo>
    </v-row>
    <Layout :width="12">
      <v-card>
        <v-stepper v-model="actual_page" alt-labels>
          <v-stepper-header>
            <v-stepper-step :complete="actual_page > 1" step="1" color="white" 
              >Información</v-stepper-step
            >
            <v-divider></v-divider>

            <v-stepper-step :complete="actual_page > 2" step="2" color="white"
              >Desarrollo</v-stepper-step
            >
            <v-divider></v-divider>

            <v-stepper-step :complete="actual_page > 3" step="3" color="white"
              >Evaluación</v-stepper-step
            >

            <v-divider></v-divider>
            <v-stepper-step :complete="actual_page > 4" step="4" color="white"
              >Confirmación</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">

               <p>Estimado/a Supervisor/a, bienvenido al Sistema de Prácticas del Departamento de Informática de la UTFSM</p>

                <p>Nuestro/a estudiante {{evaluation.practica.alumno.first_name}} {{evaluation.practica.alumno.last_name}} ha dado por finalizada su Práctica {{evaluation.practica.tipo}} con una duración de {{evaluation.practica.modo}} 
                  realizada en {{evaluation.practica.sucursal.empresa.nombre}}.
                </p>

                <p>La práctica fue iniciada  el {{formatDate(evaluation.practica.fecha_inicio)}} y su finalización en el sistema fue el {{formatDate(evaluation.practica.fecha_termino)}}.
                </p>

                <p>Primero que todo, agradeceriamos que pudiese darnos un resumen de las tareas de las que estuvo encargado nuestro estudiante.</p>
                    <ValidationObserver v-slot="{ invalid }">
                      <TextArea
                        v-model="tareas_realizadas"
                        name="La sintesis de tareas"
                        rules="required|min:150|max:750"
                        :counter="750"
                        placeholder="Escriba aquí un resumen de las tareas realizadas por el practicante en su práctica..."
         
                      />
                <p>A continuación se le mostrará le informe y bitácoras ingresadas por el estudiante, y posteriormente deberá realizar una evaluacion de diez competencias.</p>

                  <BottomArrowsStep
                    :nextDisabled="invalid"
                    nextText="Continuar"
                    :nextAction="nextStep"
                  />
                </ValidationObserver>



  
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-container>
                <Layout :width="12">
                  <v-row>
                    <v-col cols="12" :lg="6">
                      <h3 class="mb-3">Informe completado</h3>
                      <Informe
                        :evaluation="evaluation.informe"
                        :oneline="true"
                      ></Informe>
                    </v-col>
                    <v-col cols="12" :lg="6">
                        <h3 class="mb-3">Bitácoras ingresadas por el practicante</h3>
                        <P class="mb-3">Estas son todas las bitacoras ordenadas desde el inicio al final de la práctica.</P>
    

                      <DarkCard :noborder="true">
                        <v-list
                          color="transparent"
                          class="overflow-y-auto"
                          max-height="650"
                          style="height: 650px"
                          :class="scrollbarTheme"
                        >
                          <BitacoraDetail
                            v-for="bitacora in evaluation.bitacoras"
                            :key="bitacora.id"
                            :bitacora="bitacora"
                            :evaluador="true"
                            :editable="false"
                          ></BitacoraDetail>
                        </v-list>
                      </DarkCard>
                    </v-col>
                  </v-row>
                </Layout>

                <BottomArrowsStep
                  backText="Atrás"
                  nextText="Continuar"
                  :backAction="previousStep"
                  :nextAction="nextStep"
                />
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="3">
              
                <v-row justify="center" >
                  <v-col
                    cols="12"
                    lg="8"  
                    class="pa-5"
                    v-for="competencia in evaluation.competencias"
                    :key="competencia.id"
                  >
                  <!-- Componente que tiene cada cuadrado para evaluar una compentencia -->
                  <ItemEvaluacion :competencia="competencia" :notas="notas" />
                   
                  </v-col>
                </v-row>

              <BottomArrowsStep
                backText="Atrás"
                nextText="Continuar"
                :nextDisabled="!enviar_evaluacion"
                :backAction="previousStep"
                :nextAction="nextStep"
              />
            </v-stepper-content>

            <v-stepper-content step="4">
              <ValidationObserver v-slot="{ invalid }">
                <v-container>
                  <Layout :width="8">
                    <v-row justify="center" class="align-center">
                      <h3 class="pa-5">
                        Por favor, ingrese algunas observaciones, feedback y/o criticas constructivas hacia nuestro estudiante y su trabajo en la práctica.
                      </h3>
                    </v-row>

                    <TextArea
                      v-model="observaciones_alumno"
                      name="Las observaciones"
                      rules="required|min:150|max:750"
                      :counter="750"
                      placeholder="Observaciones, feedback y/o criticas constructivas sobre el practicante..."
                      :height="250"
                    />

                    <v-row justify="center" class="align-center">
                      <h3 class="pa-5">
                        Basándose en lo completado por el practicante, sus
                        bitácoras y su trabajo en la práctica ¿Aprueba o rechaza
                        al alumno?
                      </h3>
                    </v-row>
                    <v-row>
                      <v-checkbox
                        v-model="reparos"
                        label="Confirmar práctica con reparos"
                      ></v-checkbox>
                      <p v-if="evaluation.practica.tipo == 'Industrial'"> Al confirmar reparos, considera que el estudiante tiene áreas de mejora significativas que abordar. Estas observaciones y críticas constructivas serán revisadas por el encargado del sistema en la práctica profesional para evaluar el progreso realizado.</p>
                      <p v-if="evaluation.practica.tipo == 'Profesional'"> Al confirmar con reparos, considera que el estudiante tiene áreas de mejora significativas que abordar. Estas observaciones serán revisadas y consideradas como parte del proceso de cierre de su práctica profesional.</p>
                    </v-row>

                    
                  <ConfirmButton
                nextText="Aprobar al estudiante"
                backText="Reprobar al estudiante"
                :withoutIcons="true"
                :nextDisabled="invalid"
                :backDisabled="invalid"
                :backAction="() => open_dialog(0)"
                :nextAction="() => open_dialog(1)"
              />

                  </Layout>
                </v-container>
                <v-container>
                  <BottomArrowsStep
                    backText="Atrás"
                    :backAction="previousStep"
                  />

                  <action-modal
                    :active="dialog"
                    :cancelAction="() => (dialog = false)"
                    :successAction="evaluar_practica"
                    :title="
                      aprueba_practica == 0
                        ? '¿Desea reprobar al prácticante?'
                        : '¿Desea aprobar al prácticante?'
                    "
                  >
                    <p v-if="aprueba_practica == 0">
                      Al aceptar, la práctica de <strong>{{evaluation.practica.alumno.first_name + " " + evaluation.practica.alumno.last_name }}</strong> será rechazada debido a que considera que no cumple con los estandares minimos de la empresa o institución.
                    </p>
                    <p v-else>
                      Al aceptar, usted confirma que la práctica de <strong>{{evaluation.practica.alumno.first_name + " " + evaluation.practica.alumno.last_name }}</strong> cumple todos los requisitos y estandares de la empresa o institución.
                    </p>
                  </action-modal>
                </v-container>
              </ValidationObserver>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </Layout>
  </v-container>
  <v-container v-else="!evaluation">
    <EvaluacionYaHecha/>
  </v-container>
</template>

<script>
import ConfirmButton from '../../components/forms/ConfirmButton.vue'
import DarkCard from "../../components/content/DarkCard.vue";
import TextArea from "@/components/forms/TextArea.vue";
import BottomArrowsStep from "@/components/forms/BottomArrowsStep.vue";
import Layout from "@/components/content/Layout.vue";
import Informe from "@/components/practicas/components/informe";
import BitacoraDetail from "@/components/practicas/components/bitacora/BitacoraDetail";
import logo from "@/components/icons/logoDI";
import { formatDate } from "@/services/utils.service";

import ItemEvaluacion from './components/item_evaluacion.vue'
import EvaluacionYaHecha from './components/evaluacion_ya_hecha.vue'

import { ValidationObserver } from "vee-validate";
import ActionModal from "@/components/ActionModal.vue";

export default {
  components: {
    logo: logo,
    ValidationObserver,
    Informe: Informe,
    BitacoraDetail: BitacoraDetail,
    Layout,
    BottomArrowsStep,
    TextArea,
    ActionModal,
    DarkCard, 
    ConfirmButton,
    ItemEvaluacion,
    EvaluacionYaHecha
  },
  data: () => ({
    dialog: false,
    dialog_reevalucion: true,
    notas: {},
    actual_page: 1,
    tareas_realizadas: "",
    observaciones_alumno: "",
    aprueba_practica: 0,
    enviar_evaluacion: false,
    reparos: false,
  }),
  created() {
    this.$store.dispatch("tables/get", {
      endpoint: `practicas/${this.$route.params.token}/evaluar`,
      table: "evaluation_by_token",
    }).then(() => {
      if (this.evaluation.ev_previa) {
        this.tareas_realizadas = this.evaluation.evaluacion_anterior.sintesis_tareas
        this.observaciones_alumno = this.evaluation.evaluacion_anterior.observaciones_alumno
        this.notas = this.evaluation.evaluacion_anterior.notas_anterior
      }
    });
  },
  computed: {
    logged() {
      return this.$store.state.authentication.status.loggedIn;
    },
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    evaluation() {
      return this.$store.state.tables.evaluation_by_token.items;
    },
    error() {
      return this.$store.state.tables.evaluation_by_token.error;
    },
  },
  watch: {
    notas: {
      handler(val, oldVal) {
        this.validate_evaluation();
      },
      deep: true 
    },

  },
  methods: {
    formatDate,
    validate_evaluation() {
      // Se cuentan los elementos que no son undefined porque si la evaluación
      // se completa pero luego se borra una nota, siguen habiendo 10 elementos en el hash pero uno con valor undefined
      // y en este caso no se debe enviar la evaluación
      var elementos_no_undefined = Object.values(this.notas).filter(value => value !== undefined).length;
      this.enviar_evaluacion = !(elementos_no_undefined < this.evaluation.competencias.length)
    },

    open_dialog(value) {
      this.aprueba_practica = value;
      this.dialog = !this.dialog;
    },
    evaluar_practica() {
      if(this.evaluation.ev_previa) { // Si existe una evaluacion previa se edita la anterior.
        this.$store.dispatch("tables/edit", {
          endpoint: `practicas/${this.$route.params.token}/evaluar`,
          item: {
            notas: this.notas,
            observaciones_alumno: this.observaciones_alumno,
            aprueba_practica: this.aprueba_practica,
            tareas_realizadas: this.tareas_realizadas,
            reparos: this.reparos,
          },
          redirect: "/success",
        });
      }else{
        this.$store.dispatch("tables/post", { // si no se manda la nueva evaluacion
          endpoint: `practicas/${this.$route.params.token}/evaluar`,
          table: "evaluar",
          item: {
            notas: this.notas,
            observaciones_alumno: this.observaciones_alumno,
            aprueba_practica: this.aprueba_practica,
            tareas_realizadas: this.tareas_realizadas,
            reparos: this.reparos,
          },
          redirect: "/success",
        });
      }
      this.dialog = !this.dialog;
    },
    nextStep() {
      this.actual_page = this.actual_page + 1;
      window.scrollTo({ top: 0, behavior: 'smooth' }); 
      //Es importante para evitar que el supervisor se pase las primeras notas
    },
    previousStep() {
      this.actual_page = this.actual_page - 1;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style >

.v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
    border-radius: 25px ;
}
.rounded {
  height: 60px !important;
  border-radius: 20px;
}

.with_border {
  border: 1px solid #ccc !important;
  border-radius: 16px;
}

.v-stepper .v-stepper__step__step .v-icon {
  color: black !important;
}

.v-stepper__step--active .v-stepper__step__step {
  color: black !important;
}

.v-stepper--vertical .v-stepper__step--active .v-stepper__step__step {
  color: white !important;
}

.v-stepper__label {
  color: #fff !important;
}
.theme--light .v-stepper__header {
  background: #ff9800;
}
.theme--dark .v-stepper__header {
  background: #e65100;
}

.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #fff;
  border-left: 1px solid #fff;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #fff;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: #ff9800;
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>
