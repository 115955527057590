<template>
  <div ref="divCard">
  <v-card>
    <v-stepper v-model="actual_page" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="actual_page > 1" step="1" color="white"
          >Acerca de</v-stepper-step
        >
        <v-divider></v-divider>

        <v-stepper-step :complete="actual_page > 2" step="2" color="white"
          >Asignaturas</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="actual_page > 3" step="3" color="white"
          >Retroalimentación</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <div ref="divStep1">
        <v-stepper-content step="1">
          <Layout :width="10">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ValidationObserver v-slot="{ invalid }">

                    <v-row>
                      <v-col cols="12" md="6">
                        <Title
                          :center="true"
                          text="¿Cuando finalizaste tu práctica?"
                        />

                        <DatePicker
                          v-model="fecha_termino"
                          label="Fecha de finalización"
                          name="La fecha de finalizacion"
                          rules="required"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <Title
                          :center="true"
                          text="¿Cómo te conseguiste la práctica?"
                        />
                        <Select
                          label="Selecciona una opción"
                          :items="vias"
                          v-model="vias_selected"
                          itemValue="id"
                          itemText="nombre"
                          name="La vía"
                          rules="required"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <Title :center="true" text="¿Qué tareas realizaste?" />
                        <MultiAutoComplete
                          rules='required'
                          :items="tareas"
                          v-model="tareas_selected"
                          name="Las tareas realizadas"
                          itemValue="id"
                          itemText="nombre"
                          label="Selecciona multiples opciones"
                        />
                      </v-col>
                    </v-row>
                    <v-row cols="12">

                      <v-col :cols="12" lg="6">
                        <v-col>
                          <Title 
                          :center="true" 
                          text="¿Cuánto te pagaron mensualmente?" 
                          />
                          <TextField
                          label="Pago"
                          v-model="pago"
                          name="El pago mensual"
                          prefix="$"
                          rules="required|numero"
                          />
                        </v-col>
                      </v-col >

                          
                      <v-col :cols="12" lg="6" class="mt-2">
                        <Title :center="true" text="¿Tuviste algún beneficio?" />
                        <MultiAutoCompleteWithNone
                          :items="beneficios"
                          v-model="beneficios_selected"
                          name="Los beneficios"
                          itemValue="id"
                          itemText="nombre"
                          label="Selecciona multiples opciones"
                          noneLabel="No tuve ningún beneficio."
                        />
                      </v-col>
                      
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-sheet >
                          <Title text="Datos del jefe de recursos humanos" />
                          <p>Por favor ingresa los datos del Jefe de RRHH del lugar en que realizaste tu práctica.</p>
                          <TextField
                          label="Nombre y Apellido"
                          v-model="jefe_recursos_humanos.nombre_apellido"
                          name="El nombre  Jefe de RRHH"
                          rules="required|max:70"
                          
                          />
                          <TextField
                          label="E-mail"
                          v-model="jefe_recursos_humanos.email"
                          name="El email del Jefe de RRHH"
                          rules="required|email|max:50"
                          />
                        </v-sheet>
                      </v-col>
                      <v-col cols="12" md="6">
                        <Title text="Organigrama" />
                        <p>Ingresa el organigrama publico proveido por la empresa o institución, puede ser imagen o pdf. <i>(En caso de que la empresa/institución no te provea del organigrama, favor de subir un pdf indicando la situación)</i></p>
                        <FileInput
                          v-model="organigrama"
                          label="Organigrama"
                          name="El organigrama"
                          rules="required|extensionOrganigrama|size"
                          accept=".png, .jpg, .jpeg, .pdf"
                        />
                      </v-col>
                    </v-row>
                    


                    <BottomArrowsStep
                      nextText="Continuar"
                      :nextAction="() => nextStep()"
                      :nextDisabled="invalid"
                    />
                  </ValidationObserver>
                </v-col>
              </v-row>
            </v-container>
          </Layout>
        </v-stepper-content>
        </div>

        <div ref="divStep2">
        <v-stepper-content step="2">
          <Layout :width="10">
            <v-row justify="center">
              <v-col cols="12" md="8">
                <ValidationObserver v-slot="{ invalid }">
                  <Title
                    :center="true"
                    text="¿Qué asignaturas se te hicieron más útiles?"
                  />
                  <MultiAutoCompleteWithNone
                    :items="asignaturas"
                    v-model="asignaturas_selected"
                    name="Las asignaturas"
                    itemValue="id"
                    itemText="nombre"
                    label="Selecciona multiples opciones"
                    noneLabel="Ninguna asignatura."
                  />

                  <Title :center="true" text="¿Que lenguajes utilizaste?" />
                  <MultiAutoCompleteWithNone
                    :items="lenguajes"
                    v-model="lenguajes_selected"
                    name="Los lenguajes"
                    itemValue="id"
                    itemText="nombre"
                    label="Selecciona multiples opciones"
                    noneLabel="No use ningún lenguaje."
                  />

                  <Title :center="true" text="¿Que IDE utilizaste?" />
                  <MultiAutoCompleteWithNone
                    :items="ides"
                    v-model="ides_selected"
                    name="Los IDE's"
                    itemValue="id"
                    itemText="nombre"
                    label="Selecciona multiples opciones"
                    noneLabel="No use ningún IDE."
                  />

                  <Title
                    :center="true"
                    text="¿Que bases de datos utilizaste?"
                  />
                  <MultiAutoCompleteWithNone
                    :items="bds"
                    v-model="bds_selected"
                    name="Las bases de datos"
                    itemValue="id"
                    itemText="nombre"
                    label="Selecciona multiples opciones"
                    noneLabel="No use ninguna Base de Datos."
                  />

                  <Title
                    :center="true"
                    text="¿Que librerías o frameworks utilizaste?"
                  />
                  <MultiAutoCompleteWithNone
                    :items="librerias"
                    v-model="librerias_selected"
                    name="Las librerías o frameworks"
                    itemValue="id"
                    itemText="nombre"
                    label="Selecciona multiples opciones"
                    noneLabel="No use ninguna librería o framework."
                  />

                  <BottomArrowsStep
                    nextText="Continuar"
                    backText="Atrás"
                    :backAction="() => previousStep()"
                    :nextAction="() => nextStep()"
                    :nextDisabled="invalid"
                  />
                </ValidationObserver>
              </v-col>
            </v-row>
          </Layout>
        </v-stepper-content>
        </div>

        <div ref="divStep3">
        <v-stepper-content step="3">
          <Layout :width="10">
            <v-row>
              <v-col cols="12">
                <ValidationObserver v-slot="{ invalid }">

                  <v-row>
                    <v-col cols="12" lg="6">
                    <Title
                      :center="true"
                      text="¿Cómo calificarías la formación entregada por el Departamento de Informática USM?"
                    />

                    <Rating
                      v-model="rating_USM"
                      name="La evaluacion de la USM"
                      rules="required"
                    ></Rating>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <TextArea
                        v-model="comentariosUSM"
                        label="Comentarios de la USM"
                        name="El comentario de la USM"
                        counter="355"
                        rules="required|min:20|max:355"
                      />  
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="12" lg="6">

                    <Title
                      :center="true"
                      text="¿Cómo calificarías al Sistema de Prácticas?"
                      subtitle="(Por favor sé honesto, nos gustaría mejorar)"
                    />
                    <Rating
                      v-model="rating_SPDI"
                      name="La evaluacion del sistema"
                      rules="required"
                    ></Rating>
                    </v-col>

                    <v-col cols="12" lg="6">
                    <TextArea
                      v-model="comentariosSistema"
                      label="Comentarios del sistema de prácticas"
                      name="El comentario del sistema"
                      counter="355"
                      rules="required|min:20|max:355"
                    />

                    </v-col>
                  </v-row>
                  

                  <v-row>
                    <v-col cols="12" lg="6">

                    <Title
                      :center="true"
                      text="¿Cómo calificarías a la empresa?"
                      subtitle="(Esta calificación no será entregada a tu supervisor)"
                    />

                    <Rating
                      v-model="rating_empresa"
                      name="La evaluacion de la empresa"
                      rules="required"
                    ></Rating>
                    </v-col>

                    <v-col cols="12" lg="6">
                    <TextArea
                      v-model="comentariosEmpresa"
                      label="Comentarios de la Empresa"
                      name="El comentario de la empresa"
                      counter="355"
                      rules="required|min:20|max:355"
                    />

                    </v-col>
                  </v-row>

                
                  <v-row no-gutters justify="space-around" class="mb-5">
                    <h5>
                      Si ocurrió alguna situación durante la práctica en la
                      empresa y desea informarnoslo, puede hacerlo en el
                      siguiente formulario, su respuesta no será revelada a su
                      supervisor (Opcional, si no tuviste ninguna situación especial, dejar en blanco.)
                    </h5>
                  </v-row>

                  <TextArea
                    v-model="comentariosEmpresaPrivado"
                    placeholder="Escriba aquí sus observaciones..."
                  />

                  <BottomArrowsStep
                    nextText="Finalizar Práctica"
                    backText="Atrás"
                    :backAction="() => previousStep()"
                    :nextAction="() => finalizar_practica()"
                    :nextDisabled="invalid"
                  />
                </ValidationObserver>
              </v-col>
            </v-row>
          </Layout>
        </v-stepper-content>
        </div>
      </v-stepper-items>
    </v-stepper>
  </v-card>
  </div>
</template>

<script>
import Layout from "../../../components/content/Layout.vue";
import Rating from "@/components/forms/Rating.vue";
import MultiAutoComplete from "@/components/forms/MultiAutoComplete";
import Select from "@/components/forms/Select";
import TextField from "@/components/forms/TextField";
import TextArea from "@/components/forms/TextArea";
import Title from "@/components/forms/Title";
import BottomArrowsStep from "@/components/forms/BottomArrowsStep";
import { ValidationObserver } from "vee-validate";
import MultiAutoCompleteWithNone from "@/components/forms/MultiAutoCompleteWithNone.vue";
import DatePicker from "@/components/forms/DatePicker";
import FileInput from "@/components/forms/FileInput";

export default {
  components: {
    BottomArrowsStep,
    DatePicker,
    MultiAutoComplete,
    Select,
    TextField,
    TextArea,
    Title,
    ValidationObserver,
    Rating,
    MultiAutoCompleteWithNone,
    Layout,
    FileInput,
  },
  data: () => ({
    fecha_termino: null,
    comentariosSistema: null,
    rating_USM: null,
    rating_SPDI: null,
    rating_empresa: null,
    comentariosEmpresaPrivado: null,
    comentariosEmpresa: null,
    comentariosUSM: null,
    actual_page: 1,
    pago: 0,
    asignaturas_selected: [],
    vias_selected: [],
    tareas_selected: [],
    beneficios_selected: [],
    lenguajes_selected: [],
    bds_selected: [],
    ides_selected: [],
    librerias_selected: [],
    jefe_recursos_humanos: {
      nombre_apellido: null,
      email: null,
    },
    organigrama: null,
  }),
  created() {
    this.$store.dispatch("tables/get", { endpoint: `informacion/asignaturas`, table: "asignaturas" });
    this.$store.dispatch("tables/get", { endpoint: `informacion/tareas`, table: "tareas" });
    this.$store.dispatch("tables/get", { endpoint: `vias`, table: "vias" });
    this.$store.dispatch("tables/get", { endpoint: `informacion/beneficios`, table: "beneficios" });
    this.$store.dispatch("tables/get", { endpoint: `informacion/lenguajes`, table: "lenguajes_programacion" });
    this.$store.dispatch("tables/get", { endpoint: `informacion/ides`, table: "ide" });
    this.$store.dispatch("tables/get", { endpoint: `informacion/bds`, table: "bases_de_datos" });
    this.$store.dispatch("tables/get", { endpoint: `informacion/librerias`, table: "librerias" });
  },
  mounted() {
    this.autoScroll(this.$refs.divCard);
  },
  updated(){
    switch (this.actual_page){
      case 1:
        this.autoScroll(this.$refs.divStep1);
        break
      case 2:
        this.autoScroll(this.$refs.divStep2);
        break
      case 3:
        this.autoScroll(this.$refs.divStep3);
        break
      default:
        break
    }
  },
  computed: {
    asignaturas() {
      return this.$store.state.tables.asignaturas.items;
    },
    tareas() {
      return this.$store.state.tables.tareas.items;
    },
    beneficios() {
      return this.$store.state.tables.beneficios.items;
    },
    vias() {
      return this.$store.state.tables.vias.items;
    },
    lenguajes() {
      return this.$store.state.tables.lenguajes_programacion.items;
    },
    ides() {
      return this.$store.state.tables.ide.items;
    },
    bds() {
      return this.$store.state.tables.bases_de_datos.items;
    },
    librerias() {
      return this.$store.state.tables.librerias.items;
    },
  },
  methods: {
    autoScroll(el){
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    procesarPago() {// Elimina cualquier carácter que no sea un dígito
      let valorNumerico = String(this.pago).replace(/\D/g, '');
      return Number(valorNumerico);
    },
    finalizar_practica() {
      // console.log(this.$route.params.token);
      // let formData = new FormData();
      // formData.append("fecha_termino", this.fecha_termino);
      // formData.append("tareas", JSON.stringify(this.tareas_selected));
      // formData.append("beneficios", JSON.stringify(this.beneficios_selected == "none" ? [999] : [this.beneficios_selected]));
      // formData.append("asignaturas", JSON.stringify(this.asignaturas_selected == "none" ? [999] : [this.asignaturas_selected]));
      // formData.append("pago", this.procesarPago());
      // formData.append("via", this.vias_selected);
      // formData.append("librerias", JSON.stringify(this.librerias_selected == "none" ? [999] : [this.librerias_selected]));
      // formData.append("ides", JSON.stringify(this.ides_selected == "none" ? [999] : [this.ides_selected]));
      // formData.append("bds", JSON.stringify(this.bds_selected == "none" ? [999] : [this.bds_selected]));
      // formData.append("lenguajes", JSON.stringify(this.lenguajes_selected == "none" ? [999] : [this.lenguajes_selected]));
      // formData.append("observaciones_empresa", this.comentariosEmpresa);
      // formData.append("observaciones_empresa_privadas", this.comentariosEmpresaPrivado);
      // formData.append("observaciones_usm", this.comentariosUSM);
      // formData.append("observaciones_sistema", this.comentariosSistema);
      // formData.append("nota_empresa", this.rating_empresa);
      // formData.append("nota_sistema", this.rating_SPDI);
      // formData.append("nota_usm", this.rating_USM);
      // formData.append("jefe_recursos_humanos", JSON.stringify(this.jefe_recursos_humanos)); // Convertir objeto a JSON
      // formData.append("organigrama", this.organigrama);

      let form ={ fecha_termino: this.fecha_termino,
          tareas: this.tareas_selected,
          beneficios:
            this.beneficios_selected == "none"
              ? [999]
              : this.beneficios_selected,
          asignaturas:
            this.asignaturas_selected == "none"
              ? [999]
              : this.asignaturas_selected,
          pago: this.procesarPago(),
          via: this.vias_selected,
          librerias:
            this.librerias_selected == "none" ? [999] : this.librerias_selected,
          ides: this.ides_selected == "none" ? [999] : this.ides_selected,
          bds:
            this.bds_selected == "none" ? [999] : this.bds_selected,
          lenguajes:
            this.lenguajes_selected == "none" ? [999] : this.lenguajes_selected,
          observaciones_empresa: this.comentariosEmpresa,
          observaciones_empresa_privadas: this.comentariosEmpresaPrivado,
          observaciones_usm: this.comentariosUSM,
          observaciones_sistema: this.comentariosSistema,
          nota_empresa: this.rating_empresa,
          nota_sistema: this.rating_SPDI,
          nota_usm: this.rating_USM,
          jefe_recursos_humanos: this.jefe_recursos_humanos,
          organigrama: this.organigrama,
        }
      
      let formData = new FormData();

      formData.append("data", JSON.stringify(form));
      formData.append("organigrama", this.organigrama);


      this.$store.dispatch("tables/post", {
        endpoint: `practicas/${this.$route.params.token}/informe`,
        table: "informe_practica",
        item: formData,
        files: true,
        redirect: "/user_dashboard",
      });
    },
    nextStep() {
      this.actual_page = this.actual_page + 1;
    },
    previousStep() {
      this.actual_page = this.actual_page - 1;
    },
  },
};
</script>

<style>
.big {
  font-size: 3.4em;
}
/* .v-input input {
    max-height: inherit;
} */
</style>
